<template>
  <transition name="component-fade" appear>
    <div class="loading-overlay" v-if="loading"><LoaderImage /></div>
  </transition>
  <!-- booking -->
  <div class="container" v-show="car.make && bookingStep == 1">
    <div class="row">
      <div class="gallery-block" v-if="car.make">
        <swiper
          :style="{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
            '--swiper-navigation-size': '22px'
          }"
          :spaceBetween="10"
          :navigation="true"
          @slideChange="slideChange"
          :thumbs="{ swiper: thumbsSwiper }"
          :modules="modules"
          class="mySwiper2"
          ref="swiper2"
        >
          <swiper-slide v-for="slide in car.photos" :key="slide.id">
            <img :src="slide.url" />
          </swiper-slide>
          <swiper-slide v-if="(car.photos && car.photos.length < 1) || !car.photos">
            <PlaceholderImage />
          </swiper-slide>
        </swiper>
        <swiper
          @swiper="setThumbsSwiper"
          @click="recheckActiveSlide" 
          :spaceBetween="24"
          :slidesPerView="'auto'"
          :freeMode="true"
          :watchSlidesProgress="true"
          :modules="modules"
          class="mySwiper"
          ref="swiper"
        >
          <swiper-slide v-for="slide in car.photos" :key="'thumb-'+slide.id">
            <img :src="slide.url" />
          </swiper-slide>
          <swiper-slide v-if="(car.photos && car.photos.length < 1) || !car.photos">
            <PlaceholderImage />
          </swiper-slide>
        </swiper>
      </div>

      <div class="right-data-columns">
        <div class="car-card-status-line" v-if="car.fuel_type">
          <div v-if="car.vehicle_state == 'reserved'" class="car-card-status-line--reserved">{{ vehicleStateLabels.reserved }}</div>
          <div v-if="car.vehicle_state == 'unavailable'" class="car-card-status-line--enquiry">{{ vehicleStateLabels.unavailable }}</div>
          <div v-if="car.vehicle_state == 'archived'" class="car-card-status-line--archived">{{ vehicleStateLabels.archived }}</div>
          <div v-if="car.vehicle_state == 'available'" class="car-card-status-line--available">{{ vehicleStateLabels.available }}</div>
          <div v-if="car.vehicle_state == 'subscribed'" class="car-card-status-line--on-subscription">{{ vehicleStateLabels.subscribed }}</div>
          <div v-if="car.fuel_type.includes('electric')" class="car-card-status-line--electric"><ElectricIcon /></div>
          <div class="car-card-status-line--location"><LocationIcon /> {{location_options[car.account_id]}}</div>
        </div>

        <div class="car-card-make">{{car.year}} {{car.make}} {{car.model}} {{car.badge}}</div>
        <div class="car-card-badge">{{car.body_style || '—'}}</div>

        <div class="car-card-grid hide-on-mobile">
          <div class="car-card-grid-item" v-if="false">
            <OdometerIcon />
            <div class="car-card-grid-item-contents">
              <div>Odometer</div>
              <div class="car-card-grid-item-contents-output">{{ car.odometer }}</div>
            </div>
          </div>

          <div class="car-card-grid-item">
            <TransmissionIcon />
            <div class="car-card-grid-item-contents">
              <div>Transmission</div>
              <div class="car-card-grid-item-contents-output">
                {{ car.transmission ? capitalizeFirstLetter(car.transmission) : '—' }}
              </div>
            </div>
          </div>

          <div class="car-card-grid-item">
            <NumberIcon />
            <div class="car-card-grid-item-contents">
              <div>Rego number</div>
              <div class="car-card-grid-item-contents-output">{{ car.registration_number }}</div>
            </div>
          </div>

          <div class="car-card-grid-item">
            <SeatsIcon />
            <div class="car-card-grid-item-contents">
              <div>Seats</div>
              <div class="car-card-grid-item-contents-output">{{ car.seats }}</div>
            </div>
          </div>

          <div class="car-card-grid-item">
            <ConditionIcon />
            <div class="car-card-grid-item-contents">
              <div>Condition</div>
              <div class="car-card-grid-item-contents-output">{{ car.condition }}</div>
            </div>
          </div>

          <div class="car-card-grid-item">
            <DoorsIcon />
            <div class="car-card-grid-item-contents">
              <div>Doors</div>
              <div class="car-card-grid-item-contents-output">{{ car.doors }}</div>
            </div>
          </div>
        </div>

        <template v-if="car.vehicle_state != 'subscribed' && car.vehicle_state != 'reserved'">
          <div class="car-card-from hide-on-mobile">from</div>
          <div v-if="plans.data && plans.data.plans && plans.data.plans.length && plans.data.plans[0]" class="car-card-price hide-on-mobile">${{plans.data.plans[0].price + plans.data.plans[0].subscription_price}}<span class="smaller">/{{plans.data.plans[0].billing_frequency}}</span></div>
        </template>

        <template v-if="plans.data && plans.data.plans && plans.data.plans.length">
          <template v-if="car.vehicle_state == 'available'">
            <button @click="goToAddons(plans.data.plans[0])" class="button-filled hide-on-mobile">Get Started</button>
          </template>
          <template v-else>
            <button target="_blank" @click="showEnquire('default')" class="button-filled hide-on-mobile">Enquire now</button>
          </template>
        </template>
      </div>
    </div>

    <div v-if="plans.data && plans.data.plans && plans.data.plans.length" class="mobile-pagination">
      <div v-for="(plan, i) in plans.data.plans" :key="plan.id" class="plan-pagination" :class="{ 'active': i == planPage }" @click="paginatePlanMobile(i)">
        <div class="plan-pagination__name">{{plan.name}}</div>
        <div class="plan-pagination__price">${{plan.price + plan.subscription_price}}</div>
      </div>
    </div>

    <div v-if="plans.data && plans.data.plans && plans.data.plans.length" class="row plan-row">
      <div v-for="(plan, i) in plans.data.plans" :key="plan.id" class="plan-box" :class="{ 'active': i == planPage }">
        <div class="plan-box__name">
          {{plan.name}}
          <div class="car-card-price">${{plan.price + plan.subscription_price}}<span class="smaller">/{{plan.billing_frequency}}</span></div>
        </div>
        <table>
          <tbody>
            <tr>
              <td>Establishment fee</td>
              <td>${{plan.establishment_fee}}</td>
            </tr>
            <tr>
              <td>Weekly distance allowance</td>
              <td>{{plan.distance_allowance}} km</td>
            </tr>
            <tr>
              <td>Minimum term</td>
              <td>{{plan.minimum_term}} days</td>
            </tr>
            <tr>
              <td>Swap fee</td>
              <td>${{plan.swap_fee}}</td>
            </tr>
            <tr>
              <td>Days until free swap</td>
              <td v-if="plan.days_until_free_swap == 0">Not Available</td>
              <td v-else>{{plan.days_until_free_swap}}</td>
            </tr>
            <tr>
              <td>Fee per additional km</td>
              <td>${{addTrailingZeros(plan.fee_per_additional_km, 4)}}</td>
            </tr>
          </tbody>
        </table>
        <div class="car-card-price">${{plan.price + plan.subscription_price}}<span class="smaller">/{{plan.billing_frequency}}</span></div>
        
        <!-- :href="`https://signupwidget2demo.loopit.co/subscription/create?source=${plans.data.vehicle.source}&stock_no=${car.stock_number}&vin=${car.identification_number}&plan_id=${plan.id}`"  -->
        <template v-if="car.vehicle_state == 'available'">
          <button @click="goToAddons(plan)" class="button-filled">Go {{plan.name}}</button>
          <!-- <a class="get-callback" @click="showCallback">Get callback</a> -->
        </template>
        <template v-else>
          <button target="_blank" @click="showEnquire(plan.name)" class="button-filled">Enquire now</button>
        </template>
      </div>
    </div>

    <div class="section-header">Car Specs</div>
    <div class="car-card-grid-specs">
      <div class="car-card-grid-item"  v-if="false">
        <OdometerIcon />
        <div class="car-card-grid-item-contents">
          <div>Odometer</div>
          <div class="car-card-grid-item-contents-output">{{ car.odometer || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <TransmissionIcon />
        <div class="car-card-grid-item-contents">
          <div>Transmission</div>
          <div class="car-card-grid-item-contents-output">
            {{ car.transmission ? capitalizeFirstLetter(car.transmission) : '—' }}
          </div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <EngineIcon />
        <div class="car-card-grid-item-contents">
          <div>Engine size</div>
          <div class="car-card-grid-item-contents-output">{{ car.engine_size || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <NumberIcon />
        <div class="car-card-grid-item-contents">
          <div>Rego number</div>
          <div class="car-card-grid-item-contents-output">{{ car.registration_number || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <SeatsIcon />
        <div class="car-card-grid-item-contents">
          <div>Seats</div>
          <div class="car-card-grid-item-contents-output">{{ car.seats || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <PowerIcon />
        <div class="car-card-grid-item-contents">
          <div>Power</div>
          <div class="car-card-grid-item-contents-output">{{ car.power || '—'  }}kW</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <ConditionIcon />
        <div class="car-card-grid-item-contents">
          <div>Condition</div>
          <div class="car-card-grid-item-contents-output">{{ car.condition || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <DoorsIcon />
        <div class="car-card-grid-item-contents">
          <div>Doors</div>
          <div class="car-card-grid-item-contents-output">{{ car.doors || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <FuelCapacityIcon />
        <div class="car-card-grid-item-contents">
          <div>Fuel combined</div>
          <div class="car-card-grid-item-contents-output">{{ car.fuel_combined || '—'  }} l/100km</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <ColourIcon />
        <div class="car-card-grid-item-contents">
          <div>Colour</div>
          <div class="car-card-grid-item-contents-output">
            {{ car.colour ? capitalizeFirstLetter(car.colour) : '—' }}
          </div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <FuelTypeIcon />
        <div class="car-card-grid-item-contents">
          <div>Fuel type</div>
          <div class="car-card-grid-item-contents-output">
            {{ car.fuel_type ? capitalizeFirstLetter(car.fuel_type) : '—' }}
          </div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <NumberIcon />
        <div class="car-card-grid-item-contents">
          <div>Stock number</div>
          <div class="car-card-grid-item-contents-output">{{ car.nvic || '—'  }}</div>
        </div>
      </div>

      <div class="car-card-grid-item">
        <BodyStyleIcon />
        <div class="car-card-grid-item-contents">
          <div>Body style</div>
          <div class="car-card-grid-item-contents-output">
            {{ car.body_style ? capitalizeFirstLetter(car.body_style) : '—' }}
          </div>
        </div>
      </div>
    </div>

    <div class="section-header">Package inclusions</div>
    <PackageInclusions />
    <div class="section-header" v-if="featured_cars">Featured cars</div>
    <div class="car-wrapper" v-if="featured_cars">
      <BigCarBox v-for="f_car in featured_cars" :car="f_car" :key="f_car.id" :location_options="location_options" />
    </div>

    <Modal v-model="show_callback" :close="closeCallback">
      <div class="modal-container">
        <div class="modal-container--cross" @click="closeCallback"><svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m25.3327 8.54675-1.88-1.88-7.4534 7.45335-7.45328-7.45335-1.88 1.88 7.45328 7.45335-7.45328 7.4533 1.88 1.88 7.45328-7.4533 7.4534 7.4533 1.88-1.88-7.4534-7.4533z" fill="#ced0d0"/></svg></div>
        <template v-if="!form_success">
          <div class="modal-container--header">Callback</div>
          <label class="modal-container--label">Name</label>
          <input class="modal-container--input" type="text" v-model="callback.name" required placeholder="Name" />
          <label class="modal-container--label">Phone</label>
          <input class="modal-container--input" type="text" v-model="callback.phone" required placeholder="+1234567890" />
          <label class="modal-container--label">Email</label>
          <input class="modal-container--input" type="email" v-model="callback.email" required placeholder="name@example.com" />
          <button class="button-filled" @click="sendCallback" :disabled="loading || (!callback.name || !callback.phone)">Call me</button>
          <div class="small-recaptcha">Secured by reCaptcha</div>
        </template>
        <template v-else>
          <div class="modal-container--header">Callback requested!</div>
          <button class="button-filled" @click="closeCallback">Close</button>
        </template>
      </div>
    </Modal>
    <Modal v-model="show_enquire" :close="closeEnquire">
      <div class="modal-container">
        <div class="modal-container--cross" @click="closeEnquire"><svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m25.3327 8.54675-1.88-1.88-7.4534 7.45335-7.45328-7.45335-1.88 1.88 7.45328 7.45335-7.45328 7.4533 1.88 1.88 7.45328-7.4533 7.4534 7.4533 1.88-1.88-7.4534-7.4533z" fill="#ced0d0"/></svg></div>
        <template v-if="!form_success">
          <div class="modal-container--header">Enquire Now</div>
          <label class="modal-container--label">Name <sup style="color: red;">*</sup></label>
          <input class="modal-container--input" type="text" v-model="enquire.name" required placeholder="Name" />
          <div class="modal-container--two-columns">
            <div>
              <label class="modal-container--label">Phone <sup style="color: red;">*</sup></label>
              <input class="modal-container--input" type="text" v-model="enquire.phone" required placeholder="+1234567890" />
            </div>
            <div>
              <label class="modal-container--label">Email <sup style="color: red;">*</sup></label>
              <input class="modal-container--input" type="email" v-model="enquire.email" required placeholder="name@example.com" @keyup="validateEmail" />
            </div>
          </div>
          <div style="color: red; font-family: Roboto;">{{email_message}}</div>

          <label class="modal-container--label">Preferred Subscription Start Date <sup style="color: red;">*</sup></label>
          <Datepicker class="modal-container--datepicker" :lowerLimit="date_from" type="text" v-model="enquire.date" required placeholder="DD-MM-YYYY" inputFormat="dd-MM-yyyy" />

          <label class="modal-container--label">Comments</label>
          <textarea class="modal-container--input" type="text" v-model="enquire.comments" style="min-height: 200px"></textarea>
          <button class="button-filled" @click="recaptchaEnquire" :disabled="loading || (!enquire.name || !enquire.phone || !enquire.date || !email_valid)">Call me</button>
          <div class="small-recaptcha">Secured by reCaptcha</div>
        </template>
        <template v-else>
          <div class="modal-container--header">Enquiry sent!</div>
          <button class="button-filled" @click="closeEnquire">Close</button>
        </template>
      </div>
    </Modal>
  </div>
  <!-- addon booking step 1 -->
  <div class="container" v-if="car.make && [2, 3].includes(bookingStep)">
    <div class="row-addons">
      <div class="addon-booking-container">
        <div class="addon-booking-container--breadcrumb">
          <div :class="{ 'active': bookingStep == 2, 'previous': bookingStep == 3 }">1. Mileage</div>
          <div class="circle" :class="{ 'previous': bookingStep == 3 }"></div>
          <div :class="{ 'active': bookingStep == 3 }">2. Excess</div>
        </div>
        <template v-if="bookingStep == 2">
          <div class="addon-booking-container--header">Add extra KM’s now to save upfront</div>
          
          <label for="radio-2-0" class="addon-booking-container--radio-button-line">
            <input id="radio-2-0" type="radio" v-model="km_package" value="0">
            <div class="addon-booking-container--radio-button-line--radio"></div>
            <div class="addon-booking-container--radio-button-line--text">
              <b>Standard mileage (included)</b><br>
              Total allowance {{chosen_plan.distance_allowance}} km/{{chosen_plan.billing_frequency}}
            </div>
            <div class="addon-booking-container--radio-button-line--price">
              <b>$0</b>/{{chosen_plan.billing_frequency}}
            </div>
          </label>

          <template v-for="kmpack in km_packs" :key="kmpack.id">
            <label :for="`radio-2-${kmpack.id}`" class="addon-booking-container--radio-button-line">
              <input :id="`radio-2-${kmpack.id}`" type="radio" v-model="km_package" :value="kmpack.id">
              <div class="addon-booking-container--radio-button-line--radio"></div>
              <div class="addon-booking-container--radio-button-line--text">
                <b>Add {{kmpack.distance_allowance}} km</b><br>
                Total allowance {{chosen_plan.distance_allowance + kmpack.distance_allowance}} km/{{chosen_plan.billing_frequency}}
              </div>
              <div class="addon-booking-container--radio-button-line--price">
                <b>${{kmpack.price}}</b>/{{chosen_plan.billing_frequency}}
              </div>
            </label>
          </template>
        </template>
        <template v-if="bookingStep == 3">
          <div class="addon-booking-container--header">Reduce your financial responsibility for theft or accidental damage</div>
          
          <label for="radio-3-0" class="addon-booking-container--radio-button-line">
            <input id="radio-3-0" type="radio" v-model="excess_package" value="0">
            <div class="addon-booking-container--radio-button-line--radio"></div>
            <div class="addon-booking-container--radio-button-line--text">
              <b>Standard excess (included)</b><br>
              The standard excess in the event of a claim is ${{excess_adjustments.default_insurance_excess}}
            </div>
            <div class="addon-booking-container--radio-button-line--price">
              <b>$0</b>/{{chosen_plan.billing_frequency}}
            </div>
          </label>

          <label for="radio-3-1" class="addon-booking-container--radio-button-line">
            <input id="radio-3-1" type="radio" v-model="excess_package" value="1">
            <div class="addon-booking-container--radio-button-line--radio"></div>
            <div class="addon-booking-container--radio-button-line--text">
              <b>Excess reduction</b><br>
              Reduce your excess to ${{excess_adjustments.reduced_insurance_excess}} in the event of a claim
            </div>
            <div class="addon-booking-container--radio-button-line--price">
              <b>${{excess_adjustments.liability_protection_weekly}}</b>/{{chosen_plan.billing_frequency}}
            </div>
          </label>
        </template>
        <div class="addon-booking-container--buttons-desktop">
          <button class="button short" @click="goBack">Back</button>
          <button class="button-filled short" @click="goToNextStep">Next</button>
        </div>
      </div>
      <div class="addon-booking-container--sidebar">
        <SmallCarBox :car="car" :location_options="location_options" :excess_adjustments="excess_adjustments" :chosen_plan="chosen_plan" :km_package="km_packs.find(x => x.id == km_package)" :excess_package="excess_package" />
        <div class="addon-booking-container--buttons-mobile">
          <button class="button short" @click="goBack">Back</button>
          <button class="button-filled short" @click="goToNextStep">Next</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container" v-if="not_found">
    <div class="content__wrapper"><div class="content__text-wrapper"><h1 class="content__header">Sorry, page not found</h1><div class="text-block-40">The page you are looking for might have been removed, had its name changed or is temporary unavailable.</div><a href="/" class="button_browse button_w200 burron_align-left w-button">Go to Homepage</a></div><img src="https://assets.website-files.com/62384bb64f51132129f06453/629e15a17518f2394effbc20_404.svg" loading="lazy" alt="" class="image-35"></div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

import {FreeMode,Navigation,Thumbs} from 'swiper';

import OdometerIcon from './icons/OdometerIcon.vue';
import EngineIcon from './icons/EngineIcon.vue';
import TransmissionIcon from './icons/TransmissionIcon.vue';
import NumberIcon from './icons/NumberIcon.vue';
import SeatsIcon from './icons/SeatsIcon.vue';
import PowerIcon from './icons/PowerIcon.vue';
import ConditionIcon from './icons/ConditionIcon.vue';
import ColourIcon from './icons/ColourIcon.vue';
import FuelTypeIcon from './icons/FuelTypeIcon.vue';
import DoorsIcon from './icons/DoorsIcon.vue';
import FuelCapacityIcon from './icons/FuelCapacityIcon.vue';
import BodyStyleIcon from './icons/BodyStyleIcon.vue';
import ElectricIcon from './icons/ElectricIcon.vue';
import LoaderImage from './icons/LoaderImage.vue';
import LocationIcon from './icons/LocationIcon.vue';

import SmallCarBox from './SmallCarBox.vue';
import BigCarBox from './BigCarBox.vue';

import Datepicker from 'vue3-datepicker';

import PlaceholderImage from './images/PlaceholderImage.vue';
import PackageInclusions from './elements/PackageInclusions.vue';

export default {
  name: "CarListing",
  props: {
    id: String,
    location_options: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
    OdometerIcon,
    TransmissionIcon,
    EngineIcon,
    NumberIcon,
    SeatsIcon,
    PowerIcon,
    ConditionIcon,
    ColourIcon,
    FuelTypeIcon,
    DoorsIcon,
    FuelCapacityIcon,
    BodyStyleIcon,
    ElectricIcon,
    LoaderImage,
    LocationIcon,
    SmallCarBox,
    PackageInclusions,
    BigCarBox,
    PlaceholderImage,
    Datepicker,
  },
  data() {
    return {
      email_valid: false,
      client_id: "8ad09b7a7c980e04017ca18d09ca23d9",
      car: {},
      plans: {},
      excess_adjustments: {
        default_insurance_excess: 3300,
        liability_protection_weekly: 100,
        liability_protection_enabled: true,
        reduced_insurance_excess: 1000
      },
      thumbsSwiper: ref(null),
      not_found: false,
      loading: true,
      host: "https://api.eastcoastsubscriptions.com.au/",
      planPage: 1,
      bookingStep: 1,
      modules: [FreeMode,Navigation,Thumbs],
      chosen_plan: {},
      km_package: 0,
      excess_package: 0,
      km_packs: [],
      show_callback: false,
      callback: { name: '', phone: '', email: '' },
      show_enquire: false,
      date_from: ref(new Date()),
      enquire: { 
        plan: '', name: '', phone: '', email: '', comments: '', 
        street_address: '', suburb: '', city: '', state: '', postcode: '',
        date: null
      },
      featured_cars: [],
      form_success: false,
      email_message: '',
    };
  },
  mounted() {
    this.getCar(this.id);
    this.getPlans(this.id);
  },
  computed: {
    vehicleStateLabels() {
      return this.$store.state.vehicleStateLabels;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    addTrailingZeros(num, totalLength) {
      return String(num).padEnd(totalLength, '0');
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
      window.thumbsSwiper = swiper;

      /* seems to be random issue */
      setTimeout(function() {
        if(window.thumbsSwiper.slides[0])
          window.thumbsSwiper.slides[0].classList.add('active-slide');
      }, 10);
      setTimeout(function() {
        if(window.thumbsSwiper.slides[0])
          window.thumbsSwiper.slides[0].classList.add('active-slide');
      }, 100);
    },
    slideChange(slider) {
      this.thumbsSwiper.slideTo(slider.activeIndex);
      this.thumbsSwiper.activeIndex = slider.activeIndex;
      this.thumbsSwiper.setProgress(100 / (slider.slides.length - 1) / 100 * slider.activeIndex, 200);

      setTimeout(function() {
        window.thumbsSwiper.slides.removeClass('active-slide');
        window.thumbsSwiper.slides[slider.activeIndex].classList.add('active-slide');
      }, 1)
    },
    recheckActiveSlide(slide) {
      this.$refs.swiper2.$el.swiper.slideTo(slide.clickedIndex);
      this.thumbsSwiper.setProgress(100 / (this.thumbsSwiper.slides.length - 1) / 100 * slide.clickedIndex, 200);
    },
    getCar(id) {
      let api = `${this.host}/api/view.json?id=${id}`;

      this.axios.get(api).then((response) => {
        this.car = response.data;
        this.loading = false;
        document.title = `${this.car.year} ${this.car.make} ${this.car.model}`;

        this.getFeaturedCars();
      }).catch((e) => {
        console.log(e);
        this.not_found = true;
        this.loading = false;
        document.title = `404 Not Found`;
      });
    },
    getPlans(id) {
      let api = `${this.host}/api/fetch_plans.json?id=${id}`;

      this.axios.get(api).then((response) => {
        this.plans = response.data.plans
        this.plans.data.plans = this.plans.data.plans.filter(n => n)
        
        this.excess_adjustments = response.data.plans.data.vehicle.excess_adjustments

        for(let plan of this.plans.data.plans) {
          plan.subscription_price = Math.round(this.car.subscription_price);
          for(let adjustment of this.plans.data.vehicle.adjustments) {
            if (plan.id == adjustment.plan_id) {
              plan.price = Math.round(adjustment.plan_price);
              plan.subscription_price = Math.round(adjustment.subscription_price);
            }
          }
        }
      });
    },
    getKmPacks() {
      this.km_packs = this.plans.data.km_packs;
    },
    getFeaturedCars() {
      let api = `${this.host}/api/search.json`
      let query = {
        page: 1,
        location: [this.car.account_id],
        not_id: this.car.id,
        featured: "true",
      };
      let queryString = Object.keys(query).map(key => {
          if (query[key].constructor === Array) {
              var theArrSerialized = ''
              for (let singleArrIndex of query[key]) {
                  theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
              }
              return theArrSerialized
          }
          else {
              return key + '=' + query[key] + '&'
          }
      }).join('');

      this.axios.get(api + '?' + queryString).then((response) => {
        this.featured_cars = response.data.cars;
      })
    },
    paginatePlanMobile(i) {
      this.planPage = i;
    },
    goToAddons(plan) {
      this.chosen_plan = plan;
      this.getKmPacks();
      let scrollTo = document.body.clientWidth < 600 ? 300 : 120
      window.scroll({
        top: scrollTo, 
        left: 0, 
        behavior: 'smooth' 
      });

      this.loading = true;
      let that = this;
      setTimeout(function() {
        that.loading = false;
        that.bookingStep = 2;

        var dataLayer = window.dataLayer || [];
        if(dataLayer) {
          //dataLayer.push({...})
        }
      }, 250)
    },
    goBack() {
      this.bookingStep -= 1;

      let scrollTo = document.body.clientWidth < 600 ? 300 : 120
      window.scroll({
        top: scrollTo, 
        left: 0, 
        behavior: 'smooth' 
      });
    },
    goToNextStep() {
      var dataLayer = window.dataLayer || [];
      if (this.bookingStep == 2) {
        this.bookingStep = 3;

        let scrollTo = document.body.clientWidth < 600 ? 300 : 120
        window.scroll({
          top: scrollTo, 
          left: 0, 
          behavior: 'smooth' 
        });

        if(dataLayer) {
          //dataLayer.push({...})
        }
      }
      else {
        if(dataLayer) {
          console.log('dataLayer detected')
          //dataLayer.push({...})
          let excess = 'no_excess';
          if (this.excess_package === "1") { excess = 'excess_selected'; }
          let chosen_pack = 'default';
          for(let kmpack of this.km_packs) {
            if (kmpack.id == this.km_package) {
              chosen_pack = kmpack.distance_allowance;
            }
          }

          dataLayer.push({
            'km_pack': chosen_pack,
            'excess': excess,
            'event': 'checkout'
          });
        }

        this.openBooking();
      }
    },
    openBooking() {
      let url = `https://signup.loopit.co/subscription/create?source=${this.plans.data.vehicle.source}&stock_no=${this.car.stock_number}&vin=${this.car.identification_number}&plan_id=${this.chosen_plan.id}&liability_protection=${this.excess_package}&kmpack_id=${this.km_package}`;
      window.open(url, '_blank');
    },
    showCallback() {
      this.show_callback = true
    },
    sendCallback() {
      var dataLayer = window.dataLayer || [];

      if(dataLayer) {
        dataLayer.push({
          'event': 'enquire',
          'submitted': true,
          'selected_car': `${this.car.year} ${this.car.make} ${this.car.model} ${this.car.badge}`,
          'location': `${this.location_options[this.car.account_id]}`,
        });
      }

      this.loading = true;
      let api = `${this.host}/api/contact.json?type=callback`;
      let form = {
        car_id: this.car.id,
        name: this.callback.name,
        phone: this.callback.phone,
        email: this.callback.email,
      }
      this.axios.post(api, form).then(() => {
        this.loading = false;
        this.form_success = true;
      }).catch(() => {
        this.loading = false;
      });
    },
    closeCallback() {
      this.show_callback = false
    },
    showEnquire(plan) {
      this.show_enquire = true;
      this.enquire.plan = plan;
    },
    async recaptchaEnquire() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      // Do stuff with the received token.
      this.sendEnquire(token);
    },
    sendEnquire(recaptcha) {
      var dataLayer = window.dataLayer || [];

      if(dataLayer) {
        dataLayer.push({
          'event': 'enquire',
          'submitted': true,
          'selected_car': `${this.car.year} ${this.car.make} ${this.car.model} ${this.car.badge}`,
          'location': `${this.location_options[this.car.account_id]}`,
        });
      }

      this.loading = true;
      let api = `${this.host}/api/contact.json?type=enquire`;
      let form = {
        recaptcha: recaptcha,
        car_id: this.car.id,
        name: this.enquire.name,
        phone: this.enquire.phone,
        email: this.enquire.email,
        comments: this.enquire.comments,
        plan: this.enquire.plan,
        date: this.enquire.date,
        //address: `${this.enquire.street_address}, ${this.enquire.suburb}, ${this.enquire.city}, ${this.enquire.state}, ${this.enquire.postcode}`
      }
      this.axios.post(api, form).then(() => {
        this.loading = false;
        this.form_success = true;
      }).catch(() => {
        this.loading = false;
      });

    },
    validateEmail() {
      let regex = /^\S+@\S+\.\S+$/;
      if (regex.test(this.enquire.email)) {
        this.email_message = '';
        this.email_valid = true;
        return true;
      } else {
        this.email_message = 'Please enter a valid email address';
        this.email_valid = false;
        return false;
      }
    },
    closeEnquire() {
      this.show_enquire = false
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1224px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 900px) {
    padding: 0;
  }
}
.row {
  display: flex;
  @media (max-width: 1200px) {
    /* flex-direction: column-reverse; */
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
.row-addons {
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.car-card-status-line {
  padding: 0;
  height: 24px;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Roboto';

  &--enquiry {
    display: inline-block;
    height: 24px;
    background: #52BDC4;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--archived {
    display: inline-block;
    height: 24px;
    background: #747676;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--on-subscription {
    display: inline-block;
    height: 24px;
    background: #DFB80D;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--available {
    display: inline-block;
    height: 24px;
    background: #2ED27A;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--reserved {
    display: inline-block;
    height: 24px;
    background: #CACD38;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--location {
    display: inline-flex;
    height: 24px;
    background: transparent;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
    color: #1A1B1B;
    align-items: center;
    vertical-align: middle;

    svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
    }
    svg:deep(g) {
      fill: #1A1B1B !important;
    }
  }
}
.car-card-status-line--electric {
  display: inline-block;
  height: 24px;
  background: #52BDC4;
  border-radius: 8px;
  padding: 0 7px 0;
  margin: 0px 4px 0 0;
  font-style: normal;
  box-sizing: border-box !important;

  svg {
    margin: 0 0;
    position: relative;
    top: 4px;
  }
}
.car-card-make {
  margin-top: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 58px;
  letter-spacing: -0.03em;
  color: #1A1B1B;
}
.car-card-badge {
  margin-top: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #1A1B1B;
}

.plan-row {
  margin-top: 24px;

  @media (max-width: 900px) {
    margin-top: 0;
  }

}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 380px;
  width: 100%;

  @media (max-width: 900px) {
    height: 64%;
  }
}

.mySwiper {
  height: 23.5%;
  box-sizing: border-box;
  padding: 24px 0;

  @media (max-width: 900px) {
    padding: 20px 20px;
  }
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
  cursor: pointer;
}

//.mySwiper .swiper-slide-thumb-active {
.mySwiper .active-slide {
  opacity: 1;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    border: 5px solid #52BDC4;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }

  img {
    position: relative;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-block {
  max-width: 740px;
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 61vw;
  }
  @media (max-width: 900px) {
    max-width: 900px;
    margin: 24px auto;
  }
}
.right-data-columns {
  margin-left: 73px;
  text-align: left;

  @media (max-width: 900px) {
    margin-left: 0;
    padding: 0 20px;
  }
}

.hide-on-mobile {
  @media (max-width: 900px) {
    display: none !important;
  }
}

.car-card-grid {
  margin-top: 18px;
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."
    ". ."
    ". ."; 
}
.car-card-grid-specs {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". . ."
    ". . ."
    ". . ."; 
  text-align: left;

  .car-card-grid-item {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) { 
    padding: 0 20px;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-template-areas: 
      ". ."
      ". ."
      ". ."; 
  }
}
.car-card-grid-item {
  display: flex;
  margin-bottom: 12px;
}
.car-card-grid-item svg {
  margin-right: 16px;
  flex-shrink: 0;
}
.car-card-grid-item-contents {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #747676;
}
.car-card-grid-item-contents-output {
  font-size: 16px;
  line-height: 24px;
  color: #1A1B1B;
}
.car-card-from {
  margin-top: 8px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1A1B1B;
}
.car-card-price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: center;
  color: #1A1B1B;

  .smaller {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}
.button {
  width: 320px;
  height: 56px;
  background: transparent;
  border: 1px solid #52BDC4;
  border-radius: 50px;
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #52BDC4;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  margin-top: 19px;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &.short {
    width: 106px;
    margin-right: 16px;
  }
}
.button-filled {
  width: 320px;
  height: 56px;
  background: #52BDC4;
  border: 1px solid #52BDC4;
  border-radius: 50px;
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: white;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  margin-top: 19px;
  cursor: pointer;
  transition: 0.25s ease-in-out;

  &.short {
    width: 106px;
  }
  &:hover {
    border: 1px solid #38B4BC;
    background: #38B4BC;
  }
  &:active {
    border: 1px solid #1EAAB3;
    background: #1EAAB3;
  }
  &:disabled {
    border: 1px solid #CED0D0;
    background: #CED0D0;
    cursor: not-allowed;
  }
}
.section-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1A1B1B;
  margin-bottom: 48px;
  margin-top: 86px;

  @media (max-width: 900px) { 
    text-align: left;
    letter-spacing: -0.03em;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 24px;
    margin-top: 60px;
    padding: 0 20px;
  }
}

.plan-box {
  text-align: left;
  width: 100%;
  border-radius: 20px;
  padding: 31px 48px 52px;

  .button-filled {
    margin-top: 24px;
    background: transparent;
    color: #52BDC4;
    transition: 0.25s ease-in-out;


    @media (max-width: 900px) {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    display: none;
    background: #F0F2F2;
    border-radius: 0;
    padding: 32px 20px 36px;

    &.active {
      display: block;
      background: #FFF6CF;

      .button-filled {
        background: #52BDC4;
        color: #fff;
        transition: 0.25s ease-in-out;
        &:hover {
          border: 1px solid #38B4BC;
          background: #38B4BC;
        }
        &:active {
          border: 1px solid #1EAAB3;
          background: #1EAAB3;
        }
      }
    }
  }
}
.plan-box:nth-child(2) {
  background: #FFF6CF;

  .button-filled {
    background: #52BDC4;
    color: #fff;
    transition: 0.25s ease-in-out;
    &:hover {
      border: 1px solid #38B4BC;
      background: #38B4BC;
    }
    &:active {
      border: 1px solid #1EAAB3;
      background: #1EAAB3;
    }
  }
}
.plan-box__name{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #1A1B1B;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    display: none;
  }

  & .car-card-price {
    font-size: 33px;
    line-height: 36px;
    color: #32BDC4;
    margin-left: auto;

    & .smaller {
      line-height: 22px;
      font-size: 14px;
      margin-top: 12px;
    }
  }
}
.plan-box table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 22px;

  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
}
.plan-box tr td {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1A1B1B;
  padding-bottom: 10px;
}
.plan-box table td:nth-child(2) {
  text-align: right;
  font-weight: bold;
}
.loading-overlay {
  width: 150px;
  position: fixed;
  top: 240px;
  left: 50%;
  z-index: 200;
  transform: translateX(-50%);
}


.mobile-pagination {
  margin-top: 32px;
  display: none;

  @media (max-width: 900px) {
    display: flex;
  }

  .plan-pagination {
    width: 33.3%;
    background: #F0F2F2;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #747676;
    font-family: 'Roboto';
    font-style: normal;
    cursor: pointer;

    &__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    &__price {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.005em;
    }
    &.active {
      background: #FFF6CF;
      border-top: 5px solid #DFB80D;
      color: #1A1B1B;
    }
  }
}
.addon-booking-container {
  width: 100%;
  text-align: left;
  font-family: 'Roboto';

  @media (max-width: 900px) {
    margin: 0 15px;
  }

  &--header {
    margin-top: 40px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: #1A1B1B;
  }

  &--breadcrumb {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #CED0D0;
    display: flex;

    .active {
      color: #1A1B1B;
    }
    .circle {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      margin: 0 16px;
      background: #1A1B1B;
      &.previous {
        background: #52BDC4;
      }
    }
    .previous {
      color: #52BDC4;
    }
  }

  &--radio-button-line {
    display: flex;
    max-width: 380px;
    width: 90%;
    cursor: pointer;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1A1B1B;
    margin-bottom: 24px;

    input[type="radio"] {
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
      position: absolute;
    }

    &--radio {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border: 2px solid #CED0D0;
      border-radius: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-radius: 10px;
        background: transparent;
        transition: all 0.15s ease-in-out;
      }

      input:checked + &::before {
        width: 10px;
        height: 10px;
        background:  #52BDC4;
      }
    }
    &--text {
      margin: 0 12px;
      font-weight: initial;

      b {
        font-weight: 700;
      }
    }
    &--price {
      margin: 0 0 0 auto;
      font-weight: initial;
    }
  }

  &--buttons-desktop {
    display: flex;
    margin-bottom: 48px;

    @media (max-width: 900px) {
      display: none;
    }
  }
  &--buttons-mobile {
    display: none;
    margin-bottom: 48px;

    .button-filled {
      flex-grow: 1;
    }

    @media (max-width: 900px) {
      display: flex;
    }
  }
  &--sidebar {
    flex-shrink: 0;

    @media (max-width: 900px) {
      margin: 0 15px;
    }
  }
}

.button_browse.button_w200.burron_align-left {
  color: white;
}
.text-block-40 {
  text-align: left;
}
.content__wrapper {
  padding: 110px 0;
}
.content__header {
  text-align: left;
}
.modal-container {
  width: 100%;
  max-width: 480px;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px 48px 60px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 600px) {
    padding: 32px 24px 48px;
  }
  
  &--cross {
    right: 20px;
    top: 20px;
    position: absolute;
    cursor: pointer;
  }
  &--header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1A1B1B;
    margin-bottom: 24px;
  }
  &--label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1A1B1B;
  }
  &--input {
    height: 48px;
    border: 1px solid #CED0D0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 12px;
    margin: 4px 0 12px 0;
    z-index: 20;
    position: relative;
    line-height: 40px;
    font-size: 16px;
    width: 100%;

    &::placeholder {
      color: #CED0D0;
    }
  }
  &--two-columns {
    display: flex;
    gap: 8px;

    div {
      width: 50%;
    }
  }
  .button-filled {
    margin: 28px auto 0;
    width: 200px;
  }
}
.get-callback {
  margin: 24px auto 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #52BDC4;
  cursor: pointer;
  display: block;
  width: 120px;
  transition: 0.25s ease-in-out;

  &:hover {
    color: #38B4BC;
  }
}
.car-wrapper {
  max-width: 1200px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 64px;
  
  .car-card {
    width: 272px;
    margin: 0px 12px 24px;

    @media (max-width: 600px) {
      width: calc(100% - 24px);
    }
  }
}
.swiper-slide svg {
  height: 100%;
  width: 100%;
}
.small-recaptcha {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: -20px;
}
</style>
<style>
.vue-universal-modal {
  z-index: 999;
}
.grecaptcha-badge { visibility: hidden; }

.modal-container--datepicker {
  height: 48px;
  border: 1px solid #CED0D0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 12px;
  margin: 4px 0 12px 0;
  z-index: 20;
  position: relative;
  line-height: 40px;
  font-size: 16px;
  width: 100%;
}
.modal-container--datepicker::placeholder {
  color: #CED0D0;
}
.v3dp__popout {
  z-index: 9999 !important;
  font-family: "Roboto";
}
</style>