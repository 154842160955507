<template>
  <transition name="component-fade" appear>
    <div class="dark-overlay" v-if="showFilter || searchFocused" @click="closeFilters"></div>
  </transition>
  <div class="hello">
    <div class="columns-wrapper">
      <div class="header-wrapper">
        <div class="header-wrapper--header">Browse cars</div>
      </div>
      <div class="search-wrapper">
        <div class="search-input--wrapper">
          <input ref="searchinput" tabindex="-1" class="search-input" :class="{ 'focused': searchFocused }"  type="text" v-model="filter.string" @focus="focusOnSearch" @blur="defocusSearch" @keyup.enter="getList(1, false)" placeholder="Search make, model or keyword">
          <SearchIcon :class="{ 'focused': searchFocused }" />
        </div>

        <button class="filter-button-mobile" @click="displayFilters">Filters <FilterAltIcon /></button>

        <InputSelect :options="orderOptions"
                      class="dropdown-input mobile-dropdown"
                      :w="100"
                      :placeholder="'Sort by'"
                      :prepend="'Sort by:'"
                      v-bind:value="sort"
                      :onlySelect='true'
                      @input="onSortSelected"/>
      </div>
    </div>

    <div class="columns-wrapper">
      <div class="filter-wrapper" :class="{ 'active': showFilter, 'is-dragged': isDragged }" :style="{ 'top': calculatedFilterTopPosition }">
        <div 
          class="filter-wrapper--header"
          @touchstart="startDrag($event)"
          @touchmove="testDrag($event)"
          @touchend="stopDrag()"
        >
          <div class="filter-wrapper--header--grabber"></div>
          Filters
        </div>
        <FilterBlock 
          v-if="search_options"
          :search_options="search_options" 
          :location_options="location_options"
          v-model:filter="filter"
        />

        <button v-if="showFilter" class="button-filled search-button" @click="getListAndClose(1, false)">APPLY FILTERS</button>
      </div>
      <div class="car-wrapper" :class="{ 'loading': loading }">
        <transition name="component-fade" appear>
          <div class="loading-overlay" v-if="loading"><LoaderImage /></div>
        </transition>

        <div class="car-wrapper--tags" v-if="filterPresent">
          <div class="car-wrapper--tags--tag" v-if="filteredRecently.string" :key="tag">{{filteredRecently.string}} <CloseIcon @click="removeTag('string', filteredRecently.string)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.location" :key="tag">{{location_options[tag]}} <CloseIcon @click="removeTag('location', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.make" :key="tag">{{tag}} <CloseIcon @click="removeTag('make', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.body_style" :key="tag">{{tag}} <CloseIcon @click="removeTag('body_style', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.transmission" :key="tag">{{tag}} <CloseIcon @click="removeTag('transmission', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.fuel_type" :key="tag">{{tag}} <CloseIcon @click="removeTag('fuel_type', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-for="tag in filteredRecently.seats" :key="tag">{{tag}} seats<CloseIcon @click="removeTag('seats', tag)" /></div>
          <div class="car-wrapper--tags--tag" v-if="filteredRecently.year && filteredRecently.year.length == 2">Year: {{filteredRecently.year[0]}}-{{filteredRecently.year[1]}} <CloseIcon @click="removeTag('year')" /></div>
          <div class="car-wrapper--tags--tag" v-if="filteredRecently.odometer && filteredRecently.odometer.length == 2">Odometer: {{filteredRecently.odometer[0]}}-{{filteredRecently.odometer[1]}} <CloseIcon @click="removeTag('odometer')" /></div>
          <div class="car-wrapper--tags--tag-clear-all">Clear all <CloseIcon @click="clearAll" /></div>
        </div>

        <div class="car-wrapper--holder">
          <template v-for="car in cars" :key="car.id" >
            <transition name="component-fade" appear>
              <BigCarBox :car="car" :location_options="location_options" />
            </transition>
          </template>
        </div>

        <div class="car-wrapper--not-found" v-if="pagination.total === 0">
          <SearchIcon />
          <b>No results found</b>
          <span>Please try to soften filters to show results</span>
        </div>

        <button class="button-filled next-page" :disabled="extraLoading" v-if="!pagination.last_page && !pagination.out_of_range" @click="getList(filter.page + 1, true)">LOAD MORE</button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterBlock from "./FilterBlock.vue";
import InputSelect from './elements/InputSelect/InputSelect.vue';
import LoaderImage from './icons/LoaderImage.vue';
import CloseIcon from './icons/CloseIcon.vue';
import SearchIcon from './icons/SearchIcon.vue';
import FilterAltIcon from './icons/FilterAltIcon.vue';
import BigCarBox from "./BigCarBox.vue";

export default {
  name: 'CarSearch',
  props: {
    location_options: Object,
  },
  components: {
    FilterBlock,
    InputSelect,
    LoaderImage,
    CloseIcon,
    SearchIcon,
    FilterAltIcon,
    BigCarBox
  },
  data() {
    return {
      pagination: {
        last_page: true
      },
      cars: [],
      host: "https://api.eastcoastsubscriptions.com.au/",
      search_options: {},
      loading: true,
      extraLoading: false,
      filter: { 
        page: 1,
        sort: 'fastest_availability',
      },
      filteredRecently: {},
      orderOptions: [ 
                      { 'value': 'lowest_price', 'label': 'Lowest price' }, 
                      { 'value': 'highest_price', 'label': 'Highest price' },
                      { 'value': 'newest_year', 'label': 'Newest year' },
                      { 'value': 'oldest_year', 'label': 'Oldest year' },
                      { 'value': 'fastest_availability', 'label': 'Fastest availability' },
                    ],
      sort: 'lowest_price',
      showFilter: false,
      searchFocused: false,
      filterTopPosition: false,
      lastY: 56,
      isDragged: false,
    };
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('q');

    // Reset the filter object to its default state
    this.filter = { page: 1, sort: 'lowest_price' };
    this.sort = this.filter.sort;

    if (searchQuery) {
      this.filter.string = searchQuery;
      this.$nextTick(() => {
        this.$refs.searchinput.value = searchQuery; // Update the input field value
      });
    } else {
      // Load the filter object from localStorage if no search query is present
      const storedFilter = JSON.parse(localStorage.getItem('filter'));
      if (storedFilter) {
        this.filter = storedFilter;
      }
    }

    const location = urlParams.get('location');
    if (location) {
      this.filter.location = [location];
    }

    this.getFilterOptions();
    this.getList(1, false);

    this.emitter.on("getCount", () => {
      this.getCount();
    });
    this.emitter.on("commitSearch", () => {
      this.getList(1, false);
    });
  },
  computed: {
    filterPresent() {
      if (this.filteredRecently) {
        let array = ['string', 'location', 'make', 'body_style', 'transmission', 'fuel_type', 'seats', 'year', 'odometer']
        for (let index = 0; index < array.length; index++) {
          if(this.filteredRecently[array[index]] && this.filteredRecently[array[index]].length) 
            return true;
        }
      }
      return false;
    },
    calculatedFilterTopPosition() {
      if (this.showFilter) {
        return this.filterTopPosition + 'px';
      } else {
        return '100vh';
      }
    }
  },
  methods: {
    getQueryString() {
      let query = this.filter;

      return Object.keys(query).map(key => {
          if (query[key].constructor === Array) {
              var theArrSerialized = ''
              for (let singleArrIndex of query[key]) {
                  theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
              }
              return theArrSerialized
          }
          else {
              return key + '=' + query[key] + '&'
          }
      }
      ).join('');
    },
    getFilterOptions() {
      let api = `${this.host}/api/search_options.json`

      this.axios.get(api).then((response) => {
        this.search_options = response.data;
      })
    },
    getCount() {
      let api = `${this.host}/api/search_count.json`
      let queryString = this.getQueryString()

      this.axios.get(api + '?' + queryString).then((response) => {
        this.count = response.data;
        this.emitter.emit("countSuccess", this.count);
      })
    },
    getList(page, append) {
      if (this.showFilter)
        return;

      let api = `${this.host}/api/search.json`
      this.filter.page = page;
      this.$refs.searchinput.blur();

      this.filteredRecently = JSON.parse(JSON.stringify(this.filter));
      localStorage.setItem('filter', JSON.stringify(this.filter));
      this.extraLoading = true;
      if (page === 1) {
        this.loading = true;
      }
      let queryString = this.getQueryString()

      this.axios.get(api + '?' + queryString).then((response) => {
        if(append) {
          this.cars.push(...response.data.cars);
        } else {
          this.cars = response.data.cars;
        }
        this.pagination = response.data;
        this.extraLoading = false;
        this.loading = false;
        delete(this.pagination.cars);
      })
    },
    getListAndClose(page, append) {
      this.closeFilters();
      this.getList(page, append);
    },
    onSortSelected(ok) {
      this.sort = ok;
      this.filter.sort = ok;
      this.getList(1, false);
    },
    removeTag(filter, tag) {
      this.emitter.emit("removeTag", tag);
      if(filter === 'string') {
        this.filter.string = '';
        this.filteredRecently.string = '';
      }
      if(filter === 'year') {
        this.filter.year = [];
        this.filteredRecently.year = [];
        this.emitter.emit("resetRange", "year");
      }
      if(filter === 'odometer') {
        this.filter.odometer = [];
        this.filteredRecently.odometer = [];
        this.emitter.emit("resetRange", "odometer");
      }
      this.getList(1, false);
    },
    clearAll() {
      this.emitter.emit("removeTag", '*');
      this.emitter.emit("resetRange", "year");
      this.emitter.emit("resetRange", "odometer");
      this.filter.string = '';
      this.filter = {
        page: 1,
        make: [],
        sort: 'fastest_availability',
      };
      
      this.filteredRecently = {};
      this.filteredRecently.string = '';
      this.getList(1, false);
    },
    displayFilters() {
      this.showFilter = true;
      this.filterTopPosition = 56;
    },
    closeFilters() {
      this.showFilter = false;
      this.filterTopPosition = window.innerHeight;
    },
    startDrag(e) {
      this.isDragged = true;
      this.lastY = e.touches[0].clientY;
    },
    testDrag(e) {
      if (e.touches) {
        var currentY = e.touches[0].clientY;
        var difference = 0;

        if (currentY > this.lastY) {
          difference = currentY - this.lastY;
        } else if (currentY < this.lastY) {
          difference = currentY - this.lastY;
        }
        this.lastY = currentY;
        this.filterTopPosition += difference;
      }
    },
    stopDrag() {
      this.isDragged = false;
      if (this.filterTopPosition > 100) {
        this.closeFilters();
      } else {
        this.filterTopPosition = 56;
      }
    },
    focusOnSearch() {
      this.searchFocused = true;
    },
    defocusSearch() {
      this.searchFocused = false;
    }
  }
}
</script>
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  padding: 0 0 32px 0;
}
.columns-wrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 1248px;
  align-items: flex-start;

  @media (max-width: 900px) {   
    flex-direction: column;
  }
}
.header-wrapper {
  width: 25%;
  max-width: 288px;
  margin-bottom: 34px;
  flex-shrink: 0;
  text-align: left;
  align-items: left;

  &--header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 52px;
    text-align: left;

    /* identical to box height, or 130% */
    display: flex;
    align-items: left;
    letter-spacing: -0.02em;

    /* Black */
    color: #1A1B1B;

    @media (max-width: 900px) { 
      font-size: 26px;
      line-height: 30px;
    }
  }


  @media (max-width: 900px) {   
    width: 100%;
    padding: 0 20px;
    margin-bottom: 21px;
  }
}
.search-input--wrapper {
  width: 75%;
  flex-grow: 0;
  margin: 0px 12px;
  max-width: 600px;
  position: relative; 
  height: 48px;

  svg {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 22;

    &.focused {
      z-index: 31;
    }
  }

  @media (max-width: 900px) { 
    width: 100%; 
    margin: 0 0 12px 0;
  }
}
.search-input {
  height: 48px;
  /* Gray Medium */
  border: 1px solid #CED0D0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 12px;
  margin: 0;
  z-index: 20;
  position: relative;

  line-height: 40px;
  font-size: 16px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  width: 100%;

  &.focused {
    z-index: 30;
  }
}
.search-wrapper {
  flex-shrink: 0;
  width: 75%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 900px) { 
    width: 100%; 
    padding: 0 20px;
    margin-bottom: 20px;
  }
}
.dark-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: 29;
}

.filter-wrapper {
  width: 25%;
  max-width: 288px;
  flex-shrink: 0;


  @media (max-width: 900px) {   
    //display: none;
    position: fixed;
    transition: top 0.25s ease-in-out;
    top: 100vh;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: none;
    background: white;
    z-index: 200;
    border-radius: 12px 12px 0 0;
    flex-direction: column;

    &.is-dragged {
      transition: none;
    }

    &.active {
      top: 56px;
      display: flex;
    }
  }
}
.filter-button-mobile {
  display: none;
  cursor: pointer;

  @media (max-width: 900px) {  
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 12px 18px;

    width: calc(50% - 6px);
    height: 48px;
    border: 1px solid #52BDC4;
    border-radius: 8px;
    background: transparent;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #52BDC4;

  }
}
.car-wrapper {
  flex-shrink: 0;
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &--holder {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 900px) {   
      justify-content: center;
      padding: 0 20px;
    }
  }


  @media (max-width: 900px) {   
    width: 100%;
    align-items: center;
  }
}
.dropdown-input {
  margin: 0 12px;

  &.mobile-dropdown {
    @media (max-width: 900px) {   
      margin: 0 0 0 12px;
      width: calc(50% - 6px);
    }
  }
}

.button-filled {
  height: 56px;
  border: 1px solid #52BDC4;
  background: #52BDC4;
  border-radius: 50px;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.5;;
  }

  &.search-button {
    width: calc(100% - 24px);
    margin: 16px 24px 0 0;

    @media (max-width: 900px) { 
      flex-shrink: 0;
      width: calc(100% - 40px);
      margin: 16px 20px 20px;
    }
  }
  &.next-page {
    margin: 32px 0 32px 0;
    width: 244px;
  }
}
.loading-overlay {
  width: 150px;
  position: fixed;
  top: 240px;
  z-index: 200;
}

.car-wrapper--tags {
  display: flex;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  &--tag {
    background: #52BDC4;
    border-radius: 31px;
    padding: 0 8px 0 12px;
    gap: 4px;
    height: 32px;
    margin-right: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: white;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    flex-shrink: 0;

    svg {
      cursor: pointer;
    }
  }
  &--tag-clear-all {
    background: #F0F2F2;
    border-radius: 31px;
    padding: 0 8px 0 12px;
    gap: 4px;
    height: 32px;
    margin-right: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #1A1B1B;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    svg {
      cursor: pointer;

      &:deep(rect) {
        fill: #747676 !important
      }
      &:deep(path) {
        fill: #F0F2F2 !important
      }
    }
  }

  @media (max-width: 900px) {   
    padding: 0 20px;
    flex-wrap: nowrap;
    overflow: scroll;
    position: relative;
    width: 100vw;
  }
}
.car-wrapper--not-found {
  width: 100%;
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: 'Roboto';

  & svg {
    width: 80px;
    height: 80px;
  }
  svg:deep(path) {
    fill: #F0F2F2 !important;
  }
  b {
    margin-top: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #747676;
  }
  span {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #747676;
  }
}

.filter-wrapper {
  &--header {
    touch-action: none;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;

    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* Black */
    color: #1A1B1B;

    &--grabber {
      width: 32px;
      height: 4px;
      background: #CED0D0;
      border-radius: 20px;
      margin-bottom: 18px;
      display: none;

      @media (max-width: 900px) {
        display: block;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      padding: 18px 0;
      margin: 0 20px;
      flex-shrink: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      justify-content: center;
      letter-spacing: -0.005em;
      border-bottom: 1px solid #F0F2F2;
    }
  }
}
</style>
