import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state() {
    return {
      host: "https://api.eastcoastsubscriptions.com.au/",
      vehicleStateLabels: {},
    };
  },
  mutations: {
    setVehicleStateLabels(state, labels) {
      state.vehicleStateLabels = labels;
    },
  },
  actions: {
    async fetchVehicleStateLabels({ commit }) {
      commit('setVehicleStateLabels', {
        reserved: 'RESERVED',
        unavailable: 'COMING SOON',
        archived: 'ARCHIVED',
        available: 'READY NOW',
        subscribed: 'ON SUBSCRIPTION',
      });

      try {
        const response = await axios.get(`https://api.eastcoastsubscriptions.com.au/api/vehicle_state_labels.json`);
        commit('setVehicleStateLabels', response.data);
      } catch (error) {
        console.error('Failed to fetch vehicle state labels:', error);
        // Set default values on error
        commit('setVehicleStateLabels', {
          reserved: 'RESERVED',
          unavailable: 'COMING SOON',
          archived: 'ARCHIVED',
          available: 'READY NOW',
          subscribed: 'ON SUBSCRIPTION',
        });
      }
    },
  },
});

export default store;